import {Controller} from "@hotwired/stimulus"

import Rails from '@rails/ujs'
import {Turbo} from "@hotwired/turbo-rails";

console.log('included save')

export default class extends Controller {
    static targets = ['value', 'switch', 'suggestion', 'revision', 'switchPanel', 'switchYes',
        'switchNo', 'ontrue', "state"]
    static values = {hideSave: Boolean}

    connect() {
        console.log('Switch Number: connected')
        this.attribute = this.data.get('attribute')
        this.switch_value = this.data.get('switch-value')
        this.switch_attribute = this.data.get('switch-attribute')
        this.suggest = this.data.get('suggest')
    }

    changing(event) {
        if (!this.hideSaveValue) {
            this.stateTarget.innerHTML = '<i class="fal fa-save text-danger"></i>'
        }
    }

    switchYesClicked(event) {
        console.log('#switchYesClicked')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=1',
                dataType: 'json',
                success: function (response) {
                    that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
                    that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '1')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    switchYesClickedTurbo(event) {
        console.log('#switchYesClickedTurbo')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        data.append(this.switchYesTarget.name, '1')

        let url

        url = this.url()

        if (that.typeValue) {
            url = url + `?type=${this.typeValue}`
        }

        fetch(url, {
            method: 'PATCH',
            body: data,
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
        that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
    }


    switchNoClicked(event) {
        console.log('#swtichNoChange')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.switch_attribute + '&value=0',
                dataType: 'json',
                success: function (response) {
                    that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {

            data.append(this.switchYesTarget.name, '0')

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    switchNoClickedTurbo(event) {
        console.log('#swtichNoChangeTurbo')

        let that = this

        if (this.switch_value === '0') {
            this.data.set('switch-value', '1')
        } else {
            this.data.set('switch-value', '0')
        }
        this.switch_value = this.data.get('switch-value')

        let data = new FormData()

        data.append(this.switchYesTarget.name, '0')

        let url = this.url()


        if (that.typeValue) {
            url = url + `?type=${this.typeValue}`
        }

        fetch(url, {
            method: 'PATCH',
            body: data,
            headers: {
                Accept: "text/vnd.turbo-stream.html",
                "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]')?.content,
            }
        })
            .then(r => r.text())
            .then(html => Turbo.renderStreamMessage(html))
        that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
        that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
    }


    saveChange(event) {
        let that = this

        let data = new FormData()

        if (this.suggest === 'yes') {

            console.log('suggest change')

            Rails.ajax({
                type: 'GET',
                url: this.url() + "?attribute=" + this.attribute + '&value=' + this.valueTarget.value,
                dataType: 'json',
                success: function (response) {
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-lg"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })

        } else {
            data.append(this.valueTarget.name, this.valueTarget.value)

            Rails.ajax({
                type: 'PATCH',
                url: this.url(),
                dataType: 'json',
                data: data,
                success: function (response) {
                    if (!that.hideSaveValue) {
                        that.stateTarget.innerHTML = '<i class="fal fa-save text-success fa-lg"></i>'
                    }
                },
                error: function (response) {
                    console.log('Setting could not be saved.')
                }
            })
        }
    }

    promote(event) {
        let that = this

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_promote?attribute=" + this.switch_attribute,
            dataType: 'json',
            success: function (response) {

                console.log(response.value)

                if (response.value === true || response.value === '1') {
                    that.switchYesTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> No'
                    that.switchYesTarget.classList.add('bg-info')
                    that.switchYesTarget.classList.add('text-white')
                    that.switchNoTarget.classList.remove('bg-info')
                    that.switchNoTarget.classList.remove('text-white')
                } else {
                    that.switchYesTarget.innerHTML = '<i class="far fa-circle fa-lg"></i> Yes'
                    that.switchNoTarget.innerHTML = '<i class="far fa-dot-circle fa-lg"></i> No'
                    that.switchNoTarget.classList.add('bg-info')
                    that.switchNoTarget.classList.add('text-white')
                    that.switchYesTarget.classList.remove('bg-info')
                    that.switchYesTarget.classList.remove('text-white')
                }


                that.revisionTarget.innerHTML = ''
                console.log('cleared suggestion')
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }


    url() {

        console.log('getting url')

        if (this.suggest === 'yes') {
            return this.switchYesTarget.closest('form').getAttribute('action') + '/revision_suggest';
        } else {
            return this.switchYesTarget.closest('form').getAttribute('action');
        }

        console.log('Got url')

    }

    removeRevision(event) {
        let that = this

        // Suggestion has been promoted so lets clear the change.
        Rails.ajax({
            type: 'GET',
            url: this.url() + "/revision_suggest?attribute=" + this.switch_attribute,
            dataType: 'json',
            success: function (response) {
                that.revisionTarget.innerHTML = ''
            },
            error: function (response) {
                console.log('Setting could not be saved.')
            }
        })
    }
}